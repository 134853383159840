'use strict'

import axios from "axios";

export default {
    getLogs: (state, data ) => {
        return new Promise( done => {
            axios.get( `/api/logs/list/${data.worker}/${data.page}/${data.perPage}/?s=${encodeURIComponent(data.searchTerm)}` )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    deleteLog: (state, data) => {
        return new Promise( done => {
            axios.delete( '/api/logs/delete/' + data.id )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
}