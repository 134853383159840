'use strict'

import axios from "axios";

export default {
    getEldoradoOffers: ( ) => {
        return new Promise( done => {
            axios.get( '/api/eldorado-offer/list' )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getSingleEldoradoOffer: (state, data) => {
        return new Promise( done => {
            axios.get( '/api/eldorado-offer/' + data.id )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getEldoradoOfferFee: (state, data) => {
        return new Promise( done => {
            axios.get( `/api/eldorado-offer/get-fee/${data.id}/` )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getStockPrices: (state, data) => {
        return new Promise( done => {
            axios.get( `/api/eldorado-offer/get-stock-price/${data.id}/` )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    duplicateEldoradoOffer: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-offer/duplicate', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    createASDAGameFromOffer: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-offer/create-sda-game', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    editEldoradoOffer: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-offer/edit', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    deleteEldoradoOffer: (state, data) => {
        return new Promise( done => {
            axios.delete( '/api/eldorado-offer/' + data.id )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    applyEldoradoOfferAction: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-offer/set-action', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    setEldoradoOfferMeta: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-offer/set-meta', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    }
}