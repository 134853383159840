<template>
  <div id="app">
	<transition name="fade" mode="out-in">
		<router-view name="root" v-if="platformsLoaded"/>
	</transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
	name: 'App',
	components: {},
	data() {
		return {
			permissions: [],
			platformsLoaded: false
		}
	},
	computed: {
		...mapState( 'misc', {
			platforms: 'platforms'
		})
	},
	methods: {
		...mapActions( 'misc', {
			getActualPlatforms: 'getActualPlatforms',
			getSettingsFrontEnd: 'getSettingsFrontEnd'
		} ),
		...mapActions( 'user', {
			getPermissions: 'getPermissions'
		}),
		...mapMutations( 'misc', {
			setMenuLinks: 'setMenuLinks'
		}),
		...mapActions( 'workers', {
			getWorkers: 'getWorkers'
		}),
		checkAuth() {
			if( localStorage.getItem( 'authbearer' ) ) {
				let myVue = this;
				window.currentUserCan = function( action, ignoreAdmin = false ) {
					return myVue.permissions.includes( action ) || (( myVue.permissions.includes( 'admin' ) && !ignoreAdmin) || myVue.permissions.includes( 'super_admin' ) );
				};

				let menuLinks = [];

				if( window.currentUserCan( 'read_order' ) || window.currentUserCan( 'admin' ) || window.currentUserCan( 'read_game' ) ) {
					menuLinks.push( {
						title: 'Orders & Stock',
						children: (()=>{
							let output = [{
								title: 'Orders',
								link: '/orders'
							}];
							if( window.currentUserCan( 'read_accounts' ) || window.currentUserCan( 'read_others_accounts' ) || window.currentUserCan( 'admin' ) ) {
								output.push( {
									title: 'My Accounts',
									link: '/accounts'
								});
							}

							return output;
						})()
					} );
				}
				if( window.currentUserCan( 'eldorado_access_offers_manager' ) || window.currentUserCan( 'admin' ) ) {
					menuLinks.push( {
						title: 'Eldorado',
						children: [{
							title: 'Offers',
							link: '/eldorado-offers'
						},{
							title: 'Boosting',
							link: '/eldorado-boosting-manager'
						}]
					} );
				}
				if( ( window.currentUserCan( 'zeusx_access_offers_manager' ) || window.currentUserCan( 'admin' ) ) && this.platforms.includes( 'zeusx' ) ) {
					menuLinks.push( {
						title: 'Zeusx',
						children: [{
							title: 'Offers',
							link: '/zeusx-offers'
						}]
					} );
				}
				if( ( window.currentUserCan( 'zeusx_access_offers_manager' ) || window.currentUserCan( 'admin' ) ) && this.platforms.includes( 'g2g' ) ) {
					menuLinks.push( {
						title: 'G2G',
						children: [{
							title: 'Offers',
							link: '/g2g-offers'
						}]
					} );
				}
				menuLinks.push( {
					title: 'Misc',
					children: (()=>{
						let output = [];
						
						if( window.currentUserCan('read_settings')) {
							output.push( {
								title: 'Settings',
								link: '/settings'
							} );
						}

						output.push({
							title: 'Users',
							link: '/users'
						});
						
						if( window.currentUserCan( 'read_logs' ) ) {
							output.push( {
								title: 'Logs',
								link: '/logs'
							});
						}

						return output;
					})()
				} );

				this.setMenuLinks(menuLinks);
			} else {
				if( this.$route.name != 'login' ) {
					this.$router.push( '/login' );
				}
			}
		}
	},
	async mounted() {

		try {
			if( localStorage.getItem( 'authbearer' ) ) {
				let [,,permissions] = await Promise.all([
					this.getWorkers(),
					this.getActualPlatforms(),
					this.getPermissions(),
				]);
				if( permissions && permissions.success ) {
					this.permissions = permissions.data;
					this.checkAuth();
					this.platformsLoaded = true;
				}
			} else throw 'no token';
		} catch {
			this.platformsLoaded = true;
			if( this.$route.name != 'login' ) {
				this.$router.push( '/login' );
			}
		}
	}
}
</script>

<style lang="scss">
/* Fading animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
.custom-container {
	@media screen and (min-width: 2000px) {
		max-width: 1400px;
	}
}
.skeleton-rect {
	max-width: 100% !important;
}
#header-menu-collapse {
	.nav-item {
		white-space: nowrap;
	}
}
/* custom scrollbar */
::-webkit-scrollbar {
	width: 20px;
}
::-webkit-scrollbar-track {
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: #d6dee1;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #a8bbbf;
}
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
