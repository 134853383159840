'use strict'

import axios from "axios";

export default {
    getActualPlatforms: (state ) => {
        return new Promise( done => {
            axios.get( '/api/misc/platforms' )
                .then( res => {
                    if( res.data && res.data.success ){
                        state.commit( 'setActualPlatforms', res.data.data );
                    }
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getSettings: ( ) => {
        return new Promise( done => {
            axios.get( '/api/misc/settings' )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getWallet: ( ) => {
        return new Promise( done => {
            axios.get( '/api/misc/wallet' )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getOfferImages: () => {
        return new Promise( done => {
            axios.get( '/api/misc/get-offer-images' )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    setSettings: ( state, data ) => {
        return new Promise( done => {
            axios.put( '/api/misc/settings', data )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    //getPossiblePermissions
    getPossiblePermissions: ( ) => {
        return new Promise( done => {
            axios.get( '/api/misc/get-possible-permissions' )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    rebootSystem: ( ) => {
        return new Promise( done => {
            axios.get( '/api/reboot' )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getSettingsFrontEnd: ( state, data ) => {
        return new Promise( done => {
            axios.get( '/api/misc/settings-frontend/' + data.setting )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
}