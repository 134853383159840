import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import {
	BootstrapVue,
} from 'bootstrap-vue'
Vue.use(BootstrapVue)

import VueFormulate from '@braid/vue-formulate'
import SelectWithImage from './components/SelectWithImage.vue';
import CustomSelect from './components/CustomSelect.vue';

Vue.use(VueFormulate, {
	library: {
		'image-select': {
			classification: 'select', // Classification as select input
			component: SelectWithImage   // Use your custom component
		},
		'custom-select': {
			classification: 'select', // Classification as select input
			component: CustomSelect   // Use your custom component
		}
	}
});

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueGoodTablePlugin from 'vue-good-table';

// import the styles 
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

import axios from "axios";

import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
Vue.use(VueVirtualScroller)

import FingerprintJS from './fingerprint.js'
FingerprintJS.load()
.then(fp => fp.get())
.then(result => {
	if (!axios.defaults.headers.common.deviceid) {
		axios.defaults.headers.common = {
			"Content-Type": "application/json",
			"deviceid": result.visitorId
		};
	}

	const authbearer =  localStorage.getItem('authbearer');
	if( authbearer && !axios.defaults.headers.common.Authorization) {
		axios.defaults.headers.common.Authorization = "Bearer " + authbearer;
	}

	new Vue({
		router,
		store,
		render: h => h(App),
	}).$mount('#app')
})

