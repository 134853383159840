export default {
    ADD_ALERT: ({commit}, data) => {
        data.id = String( Date.now() + crypto.randomUUID() );
        const timeoutToDisappear = data.delay ? parseInt( data.delay ) : 10;
        commit('addAlert', data);
        setTimeout(() => {
            commit('removeAlert', data);
        }, 1000 * timeoutToDisappear)
    },
    REMOVE_ALERT: ({commit}, data) => {
        commit('removeAlert', data);
    },
}