'use strict'

import axios from "axios";

export default {
    getReceivedBoostingRequests: (state, data) => {
        return new Promise( done => {
            axios.get( `/api/eldorado-boosting-request/list/${data.page}/${data.perPage}/?s=${encodeURIComponent(data.searchTerm)}` )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getGamesWithBoostingServices: (state, data) => {
        return new Promise( done => {
            axios.get( `/api/eldorado-boosting-request/list-game/${data.page}/${data.perPage}/?s=${encodeURIComponent(data.searchTerm)}` )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getSingleEldoradoBoostingGame: (state, data) => {
        return new Promise( done => {
            axios.get( '/api/eldorado-boosting-request/single-game/' + data.id )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    editEldoradoBoostingGame: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-boosting-request/edit-subscribed-game', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    setEldoradoBoostingGameMeta: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-boosting-request/set-game-meta', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    }
}