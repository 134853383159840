import Vue from 'vue';

export default {
    addAlert: ( state, payload ) => {
        Vue.set(state.alerts, payload.id, payload)
    },
    removeAlert: ( state, payload ) => {
        Vue.delete(state.alerts, payload.id)
    },
    cleanAlerts: ( state ) => {
        state.alerts = {};
    }
}