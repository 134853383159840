'use strict'

import axios from "axios";

export default {
    getWorkers: (state ) => {
        return new Promise( done => {
            axios.get( `/api/workers/list/` )
            .then( res => {
                if( res.data && res.data.success ){
                    state.commit( 'setWorkers', res.data.data );
                }
                done( res.data.data )
            } )
            .catch( err => {
                if( err && err.response && err.response.status && [401,403].includes(err.response.status ) && localStorage.getItem( 'authbearer' )) {
                    localStorage.removeItem( 'authbearer' );
                    window.location.reload();
                }
                done( err )
            } )
        } )
    },
    setPause: ( state, data ) => {
        return new Promise( done => {
            axios.put( '/api/workers/pause/', data )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    setWorkerReboot: ( state, data ) => {
        return new Promise( done => {
            axios.put( '/api/workers/reboot/', data )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    setWorkerSandboxed: ( state, data ) => {
        return new Promise( done => {
            axios.put( '/api/workers/sandbox/', data )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    deleteWorker: (state, data) => {
        return new Promise( done => {
            axios.delete( '/api/workers/delete/' + data.id )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
}